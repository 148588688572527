<template>
    <div class="privacy">
      <h1>Privacy Policy</h1>
      <p>Our commitment to protecting your privacy.</p>
    </div>
  </template>
  
  <script>
  export default {
    name: 'Privacy'
  }
  </script>
  
  <style scoped>
  .privacy {
    padding: 20px;
  }
  </style>